import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar from "./pages/NavigationBar";
import Home from "./pages/Home";
import FAQ from "./pages/FAQ";
// import About from "./pages/About";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import logo from "./images/logo/smartLoom.jpg";
// import Footer from "./pages/Footer";
import Blogs from "./pages/Blogs";
import Blog from "./pages/Blogs/Blog";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

const App: React.FC = () => {
  const pages = [
    { label: "Home", path: "/" },
    { label: "Products", path: "/products" },
    { label: "Blogs", path: "/blogs" },
    // { label: "About Us", path: "/about" },
    { label: "FAQs", path: "/faq" },
    { label: "Contact Us", path: "/contact" },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <NavigationBar logo={logo} pages={pages} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:page" element={<Blogs />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/blog/:id" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/about" element={<About />} /> */}
        </Routes>
        {/* <Footer /> */}
      </Router>
    </ThemeProvider>
  );
};

export default App;
