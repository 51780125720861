import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Typography, Container, Paper, Box } from "@mui/material";
import blogs from "../../resources/blogs.json";

const useStyles = makeStyles({
  container: {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
  },
  paper: {
    padding: "30px",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    boxShadow: "0 3px 10px rgba(0,0,0,0.2)",
  },
  title: {
    fontSize: "28px", // Adjusted from 36px to 28px
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#3f51b5",
    textAlign: "center",
  },
  content: {
    fontSize: "16px",
    lineHeight: "1.8",
    color: "#555",
    "& h2": {
      fontSize: "24px", // Adjusted from 28px to 24px
      fontWeight: "bold",
      margin: "20px 0 10px",
      color: "#3f51b5",
    },
    "& h3": {
      fontSize: "20px", // Adjusted from 24px to 20px
      fontWeight: "bold",
      margin: "15px 0 10px",
      color: "#3f51b5",
    },
    "& p": {
      margin: "10px 0",
    },
    "& img": {
      width: "100%",
      borderRadius: "10px",
      margin: "20px 0",
    },
  },
});

//  eslint-disable-next-line
const Blog = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const blogId = parseInt(id as string, 10);
  const blog = blogs.find((blog) => blog.id === blogId);

  if (!blog) {
    return (
      <Container className={classes.container}>
        <Typography variant="h5">Blog not found</Typography>
      </Container>
    );
  }

  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.title}>
          {blog.title}
        </Typography>
        <Box
          className={classes.content}
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />
      </Paper>
    </Container>
  );
};

export default Blog;
