import React, { useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  useTheme,
  IconButton,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

interface Product {
  id: number;
  name: string;
  description: string;
  price: string;
  images: string[];
}

const products: Product[] = [
  {
    id: 1,
    name: "Zinc Alloy Smart Locks",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "19,999/-",
    images: [
      "https://s.alicdn.com/@sc04/kf/H4459ae16a6ce48cf87358b542bac97aaF.jpg_1200x1200.jpg",
      "https://s.alicdn.com/@sc04/kf/H65542dbcdb154c0293317eacf9c3cc5bm.png_1200x1200.png",
      "https://s.alicdn.com/@sc04/kf/Hf460d16fc1b54d939667654c4663655c9.png_1200x1200.png",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
  {
    id: 2,
    name: "Product 2",
    description:
      "Security Digit Electric Electronic Handle Digital Tuya Ttlock Cerraduras Inteligentes Con Wifi Keys Fingerprint Door Smart Locks",
    price: "$20.00",
    images: [
      "https://s.alicdn.com/@sc04/kf/H414bdeeec0a6430ebe349e6a03c1df91H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H4bc28fd5cb834ad18d3464f3257e18b6H.jpg_1200x1200.jpg?avif=close",
      "https://s.alicdn.com/@sc04/kf/H1b3edabad7df4a01b3fa2a327d35e3b3m.jpg_1200x1200.jpg?avif=close",
    ],
  },
];

const Products: React.FC = () => {
  return (
    <Grid container spacing={2}>
      {products.map((product) => (
        <Grid
          key={product.id}
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ marginBottom: 2 }}
        >
          <ProductCard product={product} />
        </Grid>
      ))}
    </Grid>
  );
};

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      sx={{
        margin: "16px",
        boxShadow: isHovered
          ? "0 8px 16px rgba(0,0,0,0.2)"
          : "0 4px 8px rgba(0,0,0,0.1)",
        transition: "box-shadow 0.3s",
        "&:hover": {
          boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: 0,
          paddingBottom: "100%",
          aspectRatio: "1 / 1",
        }}
      >
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          navigation={{
            nextEl: `.swiper-button-next-${product.id}`,
            prevEl: `.swiper-button-prev-${product.id}`,
          }}
          pagination={{ clickable: true }}
          //   autoplay={{ delay: 3000, disableOnInteraction: true }}
        >
          {product.images.map((image, index) => (
            <SwiperSlide key={index}>
              <CardMedia
                component="img"
                image={image}
                alt={product.name}
                sx={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </SwiperSlide>
          ))}
          <IconButton
            className={`swiper-button-prev-${product.id}`}
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
              },
            }}
          >
            <ArrowBackIos sx={{ color: theme.palette.common.white }} />
          </IconButton>
          <IconButton
            className={`swiper-button-next-${product.id}`}
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
              },
            }}
          >
            <ArrowForwardIos sx={{ color: theme.palette.common.white }} />
          </IconButton>
        </Swiper>
      </Box>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.description}
        </Typography>
        <Typography variant="h6" color="text.primary">
          {product.price}
        </Typography>
        <Button variant="contained" color="primary" sx={{ marginTop: "10px" }}>
          View
        </Button>
      </CardContent>
    </Card>
  );
};

export default Products;
