import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
  },
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "1.5rem",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "1.25rem",
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      marginBottom: "0.75rem",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: "bold",
      marginBottom: "0.5rem",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.5",
      marginBottom: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: "1.5",
      marginBottom: "0.75rem",
    },
  },
});

export default theme;
