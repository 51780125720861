import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";

const products = [
  {
    title: "Smart Door Locks",
    description: "Secure your home with our advanced smart door locks.",
    image: "door-lock-image-url", // replace with your image URL
    link: "/products/smart-door-locks",
  },
  {
    title: "Smart Switches",
    description: "Control your lights and devices with ease.",
    image: "smart-switch-image-url", // replace with your image URL
    link: "/products/smart-switches",
  },
  {
    title: "Interior Lights",
    description: "Enhance your home ambiance with our smart lights.",
    image: "interior-lights-image-url", // replace with your image URL
    link: "/products/interior-lights",
  },
];

const Home = () => {
  return (
    <Container>
      <Typography variant="h1" align="center" gutterBottom>
        Welcome to Smart Home Solutions
      </Typography>
      <Typography variant="h5" align="center" paragraph>
        Discover our range of smart home products designed to enhance your
        living experience.
      </Typography>
      <Grid container spacing={4}>
        {products.map((product, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={product.image}
                alt={product.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {product.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {product.description}
                </Typography>
              </CardContent>
              <Button size="small" color="primary" href={product.link}>
                Learn More
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Home;
