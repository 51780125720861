import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FAQs from "../resources/FAQs.json";

const FAQ = () => {
  return (
    <Box
      sx={{ width: "100%", padding: "20px", maxWidth: "800px", margin: "auto" }}
    >
      {FAQs.map((faq, index) => (
        <Accordion
          key={index}
          sx={{
            marginBottom: "10px",
            boxShadow: 3,
            borderRadius: "10px", // Adds rounded corners
            overflow: "hidden", // Hides the overflow to round corners properly
            transition: "all 0.3s ease",
            "&:hover": {
              boxShadow: 6,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd", // Adds a border on top of the details
              borderBottomLeftRadius: "10px", // Adds rounded corners to the bottom left
              borderBottomRightRadius: "10px", // Adds rounded corners to the bottom right
            }}
          >
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
