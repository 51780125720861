import React from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Assessment } from "@mui/icons-material"; // Import the Assessment icon
import blogs from "../resources/blogs.json";

// interface Blog {
//   id: number;
//   title: string;
//   image?: string;
//   shortDescription: string;
// }

// interface BlogsProps {
//   blogs: Blog[];
// }

const useStyles = makeStyles({
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  logo: {
    height: "40px",
    marginRight: "10px",
  },
  title: {
    fontSize: "36px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  blogItem: {
    marginBottom: "40px",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
    },
  },
  blogTitle: {
    fontSize: "24px",
    marginBottom: "10px",
    textDecoration: "none",
    color: "#333",
    display: "flex",
    alignItems: "center",
  },
  description: {
    fontSize: "16px",
    color: "#666",
  },
  image: {
    width: "100%",
    borderRadius: "8px",
    marginBottom: "10px",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
  },
  pageLink: {
    margin: "0 5px",
    padding: "5px 10px",
    backgroundColor: "#eee",
    borderRadius: "5px",
    textDecoration: "none",
    color: "#333",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ccc",
    },
  },
});

const Blogs = () => {
  const classes = useStyles();
  const { page } = useParams<{ page?: string }>();
  const pageNumber = page ? parseInt(page, 10) : 1;
  const postsPerPage = 4;

  const indexOfLastPost = pageNumber * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogs.slice(indexOfFirstPost, indexOfLastPost);

  const totalPages = Math.ceil(blogs.length / postsPerPage);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Assessment fontSize="large" color="primary" />
        <h1 className={classes.title}>Blogs</h1>
      </div>
      {currentPosts.map((blog) => (
        <div key={blog.id} className={classes.blogItem}>
          <Link
            to={`/blog/${blog.id}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className={classes.blogTitle}>
              <Assessment color="primary" />
              <span>{blog.title}</span>
            </div>
            <p className={classes.description}>{blog.shortDescription}</p>
          </Link>
        </div>
      ))}
      <div className={classes.pagination}>
        {Array.from({ length: totalPages }, (_, i) => (
          <Link key={i} to={`/blogs/${i + 1}`} className={classes.pageLink}>
            {i + 1}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
